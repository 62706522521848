<!-- service-menure 热门服务三级 -->
<template>
  <div>
    <div class="service-menu">
      <div class="" style="width: 100%">
        <div
          class="service-menu-contact product"
          v-for="(item, index) in children"
          :key="index"
        >
          <h4 class="text-over-flow">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>

          <div class="btn-key">
            <a
              v-show="item.merit"
              v-for="itemmerit in (item.merit || '').split(',')"
              :key="itemmerit"
              >{{ itemmerit }}</a
            >
          </div>
          <div class="yen">
            <a class="text-darkred" style="font-size: 30px">{{ item.price }}</a>
            <span>元/年</span>
          </div>
          <div class="btn-online">
            <!-- <router-link style="margin-right: 3%"  :to="{name:'buy',query:{id:item.prodId}}" class="pro-buy">在线咨询</router-link> -->
            <router-link
              style="float: right"
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="pro-buy"
              >在线购买</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["children"],
  data() {
    return {
      merit: null,
    };
  },
  watch: {
    //正确给 cData 赋值的 方法
    children: function (newVal, oldVal) {
      this.merit = newVal; //newVal即是chartData
      this.merit = this.children[0].merit.split(",");
    },
  },
};
</script>

<style scoped></style>
