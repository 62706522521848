<!-- 热门服务 -->
<template>
  <div>
    <!--热门服务-->
    <div class="index-service">
      <div class="container">
        <div class="service-title">
          <h4>
            <a>热门服务</a> <span class="text-darkred">popular service</span>
          </h4>
        </div>
        <!-- 热门服务三种样式 -->
        <out
          v-for="(item, index) in parentsList"
          :key="index"
          :item="item"
        ></out>
      </div>

      <div class="loading-flag"></div>
      <div
        class="loading-text"
        style="text-align: center; font-size: 16px; color: #528aff"
      ></div>
    </div>
  </div>
</template>

<script>
import out from "@/views/home/components/popular/components/out.vue";
import { getParent } from "@/api/all.js";
export default {
  components: { out },
  data() {
    return {
      parentsList: [],
    };
  },
  created() {
    getParent().then((res) => {
      this.parentsList = res.data;
    });
  },
};
</script>

<style scoped></style>
