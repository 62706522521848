<!-- 电商入住 -->
<template>
  <div v-if="children.length">
    <div class="service-class" data-cnt="6">
      <div
        class="wit-banner wit-banner2"
        :style="{ background: 'no-repeat center url(' + img + ')' }"
      >
        <div>
          <h1>电商入驻</h1>
          <p>电商入驻，企业运营必备产品，欢迎采购！</p>
          <ul>
            <li class="text-over-flow"><span></span> 京东入驻</li>
            <li class="text-over-flow"><span></span> 天猫入驻</li>
          </ul>
          <div class="fn-clear"></div>
          <router-link to="/prolist" class="btn-look border-r5">
            <span class="text-relblue">查看更多 &gt;</span>
          </router-link>
        </div>
      </div>
      <div class="service-menu">
        <div class="service-menu-bankbig">
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">京东入驻</h4>
            <p>京东入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledjd/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">天猫入驻</h4>
            <p>天猫入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledtm/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">拼多多入驻</h4>
            <p>拼多多入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledpdd/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
        <div class="service-menu-bankbig">
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">真快乐入驻</h4>
            <p>真快乐入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledzkl/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">苏宁入驻</h4>
            <p>苏宁入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledsn/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="service-menu-bank product">
            <h4 class="text-over-flow">唯品会入驻</h4>
            <p>唯品会入驻，公司运营好帮手</p>

            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">2000</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <a href="settledwph/buy-index.html" class="bank-btn pro-buy"
                >在线购买</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-sever-img6.png"),
    };
  },
};
</script>

<style scoped></style>
