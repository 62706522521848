<!-- 主页 -->
<template>
  <div>
    <Header />
    <Sidebar />
    <banner />
    <entrance />
    <popular />
    <!--    <popularservice />-->
    <honor />
    <advantage />
    <mapservice />
    <news />
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import banner from "./components/banner.vue";
import entrance from "./components/entrance.vue";
import popularservice from "./components/popularService/index.vue";
import honor from "./components/honor/index.vue";
import advantage from "./components/advantage/index.vue";
import mapservice from "./components/mapService/index.vue";
import news from "./components/news/index.vue";
import popular from "./components/popular/index.vue";

export default {
  components: {
    Header,
    Footer,
    Sidebar,
    banner,
    entrance,
    popularservice,
    honor,
    advantage,
    mapservice,
    news,
    popular,
  },
  data() {
    return {};
  },
  // mounted() {
  //   window.onresize = ()=>{
  //     this.handleScreen();
  //   }
  //   window.onload = ()=>{
  //     this.handleScreen();
  //   }
  // },
  // methods: {
  //   //屏幕缩放适配
  //   handleScreen(){
  //     const m = this.detectZoom();
  //     console.log(m, 'MMMMMMMMMMMMMMM')
  //     document.body.style.zoom = 100/Number(m);
  //   },
  //   detectZoom () {
  //       let ratio = 0,
  //         screen = window.screen,
  //         ua = navigator.userAgent.toLowerCase();
  //       if (window.devicePixelRatio !== undefined) {
  //         ratio = window.devicePixelRatio;
  //       } else if (~ua.indexOf('msie')) {
  //         if (screen.deviceXDPI && screen.logicalXDPI) {
  //           ratio = screen.deviceXDPI / screen.logicalXDPI;
  //         }
  //       } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
  //         ratio = window.outerWidth / window.innerWidth;
  //       }
  //       if (ratio) {
  //         ratio = Math.round(ratio * 100);
  //       }
  //       return ratio;
  //   },
  // }
};
</script>

<style></style>
