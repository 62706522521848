<!-- 热门服务 -->
<template>
  <div>
    <!--热门服务-->
    <div class="index-service">
      <div class="container">
        <div class="service-title">
          <h4>
            <a>热门服务</a> <span class="text-darkred">popular service</span>
          </h4>
        </div>
        <!-- 热门服务四种样式 -->
        <networkpromotion />
        <domain />
        <shop />
        <financialmanagement />
        <credit />
        <promotionaltools />
      </div>

      <div class="loading-flag"></div>
      <div
        class="loading-text"
        style="text-align: center; font-size: 16px; color: #528aff"
      ></div>
    </div>
  </div>
</template>

<script>
import networkpromotion from "./networkPromotion/index.vue";
import promotionaltools from "./promotionalTools/index.vue";
import financialmanagement from "./financialManagement/index.vue";
import domain from "./domain/index.vue";
import enterprisesecurity from "./enterpriseSecurity/index.vue";
import electricity from "./electricity/index.vue";
import shop from "./shop/index.vue";
import credit from "./credit/index.vue";
import sea from "./sea/index.vue";
export default {
  components: {
    networkpromotion,
    promotionaltools,
    financialmanagement,
    domain,
    enterprisesecurity,
    electricity,
    shop,
    credit,
    sea,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
