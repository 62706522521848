<!-- 新闻 -->
<template>
  <div class="index-news">
    <div class="container">
      <div class="module-title">
        企业服务平台<span>新闻</span>动态
        <p>为您提供行业资讯、活动公告、产品发布，汇聚最前沿流行的云计算技术</p>
      </div>
      <pressrelase />
    </div>
  </div>
</template>

<script>
import pressrelase from "./pressrelease.vue";
export default {
  components: { pressrelase },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
