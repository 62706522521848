<!--  -->
<template>
  <div class="service-menu">
    <div class="service-menu-tbbig">
      <div
        class="service-menu-tb product"
        v-for="(item, index) in children"
        :key="index"
      >
        <h4 class="text-over-flow">{{ item.prodName }}</h4>
        <p>{{ item.brief }}</p>
        <div class="ec-shop">
          <div style="float: left">
            <a class="text-darkred" style="font-size: 30px">78</a
            ><span>元/年</span>
          </div>
          <div style="float: right">
            <router-link
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="ec-btn pro-buy"
              >在线购买</router-link
            >
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["children"],
};
</script>

<style scoped></style>
