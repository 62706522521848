<!-- 域名 -->
<template>
  <div v-if="children.length">
    <div class="service-class" data-cnt="4">
      <div
        class="wit-banner wit-banner2"
        :style="{ background: 'no-repeat center url(' + img + ')' }"
      >
        <div>
          <h1>{{ parent.categoryName }}</h1>
          <p>{{ parent.description }}</p>
          <ul style="width: 240px">
            <li
              v-for="(item, index) in children"
              :key="index"
              class="text-over-flow"
            >
              <span></span>
              <router-link
                style="color: #fff"
                :to="{ name: 'buy', query: { id: item.prodId } }"
                >{{ item.prodName }}
              </router-link>
            </li>
          </ul>
          <div class="fn-clear"></div>
          <router-link
            :to="{ path: '/prolist', query: { categoryId: parent.categoryId } }"
            class="btn-look border-r5"
          >
            <span class="text-relblue">查看更多 &gt;</span>
          </router-link>
        </div>
      </div>
      <service :children="children" />
    </div>
  </div>
</template>

<script>
import service from "./service-menu.vue";
import { getParent, getShoplist } from "@/api/all.js";
export default {
  components: { service },
  data() {
    return {
      parent: {},
      children: [],
      showchildren: [],
      categoryId: "",
      img: require("@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-sever-img8.png"),
    };
  },
  created() {
    //获取父亲信息
    getParent().then((res) => {
      this.parent = res.data[3];
      this.categoryId = res.data[3].categoryId;
      //传递父亲id接收子类信息
      getShoplist({ categoryId: this.categoryId, showChild: true }).then(
        (res) => {
          this.children = res.data.rows.slice(0, 2);
          this.showchildren = this.children.slice(0, 3);
        }
      );
    });
  },
};
</script>

<style scoped>
.text-over-flow {
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 3;
}
</style>
