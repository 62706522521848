<!-- 地图服务 -->
<template>
  <div class="map-service">
    <div class="cloud-product map-product">
      <!-- <p class="cloud-product-title"><span>您的业务可以遍布世界各地</span></p> -->
      <div class="module-title">
        您的业务可以<span>遍布</span>世界各地
        <p>网络拉近了人类的距离，企业服务平台推动企业服务新高度</p>
      </div>
    </div>
    <div class="auto map-service-wrapper">
      <div class="map-service-box clearfix">
        <div class="world-map-wrapper">
          <div class="world-map">
            <div class="region-list postition-1">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
            </div>
            <div class="region-list postition-2 underline-node">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
              <div class="show-regin"></div>
            </div>
            <div class="region-list postition-3">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
            </div>
            <div class="region-list postition-4">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
            </div>
            <div class="region-list postition-5">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
            </div>
            <div class="region-list active postition-6 online-node">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
              <div class="show-regin"></div>
            </div>
            <div class="region-position-introduce postition-introduce-6">
              华北
            </div>
            <div class="region-list active postition-7 online-node">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
              <div class="show-regin"></div>
            </div>
            <div class="region-position-introduce postition-introduce-7">
              华东
            </div>
            <div class="region-list active postition-8 online-node">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
              <div class="show-regin"></div>
            </div>
            <div class="region-position-introduce postition-introduce-8">
              香港
            </div>
            <div class="region-list active postition-9 online-node">
              <div class="area-box">
                <span class="dot"></span><span class="pulse"></span>
              </div>
              <div class="show-regin"></div>
            </div>
            <div class="region-position-introduce postition-introduce-9">
              华南
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
