<!--  -->
<template>
  <div class="service-menu">
    <div class="" style="width: 100%">
      <div class="service-menu-contact product">
        <h4 class="text-over-flow">京东云星盾</h4>
        <p>
          全网节点具备攻击防御能力形成分布式安全防护，为用户提供安全、快速的一体化互联网安全服务能力。
        </p>

        <div class="btn-key">
          <a>营销助手</a><a>企业必备</a><a>成长伙伴</a><a>成长专家</a>
        </div>
        <div class="yen">
          <a class="text-darkred" style="font-size: 30px">900</a>
          <span>元/年</span>
        </div>
        <div class="btn-online">
          <!-- <a style="margin-right: 3%" href="">在线咨询</a> -->
          <a href="jdstar/buy-index.html" class="pro-buy">在线购买</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
