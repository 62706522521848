<!-- service-menure 热门服务三级 -->
<template>
  <div>
    <div class="service-menu">
      <div class="" style="width: 100%">
        <div
          class="service-menu-contact product"
          v-for="(item, index) in children"
          :key="index"
        >
          <h4 class="text-over-flow">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>

          <div class="btn-key">
            <a v-show="item.merit">{{ item.merit }}</a>
          </div>
          <div class="yen">
            <a class="text-darkred" style="font-size: 30px">{{ item.price }}</a>
            <span>元/年</span>
          </div>
          <div class="btn-online">
            <!-- <router-link style="margin-right: 3%"  :to="{name:'buy',query:{id:item.prodId}}" class="pro-buy">在线咨询</router-link> -->
            <router-link
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="pro-buy"
              >在线购买</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["children"],
};
</script>

<style scoped>
a {
  display: block;
  /*超出隐藏*/
  overflow: hidden;
  /*禁止换行*/
  white-space: nowrap;
  /*超出用省略号代替*/
  text-overflow: ellipsis;
}
</style>
