<!-- 优势 -->
<template>
  <div class="index-advantage">
    <div class="advantage-list">
<!--      <div class="hd">-->
<!--        <ul>-->
<!--          <li></li>-->
<!--          <li></li>-->
<!--          <li></li>-->
<!--          <li></li>-->
<!--          <li></li>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="bd">
        <ul>
          <li class="list4">
            <div class="container">
              <h2 class="title">为什么选择企业服务平台</h2>
              <img
                src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/av-img4.png"
                alt=""
              />
              <div
                class="info"
                palyin="{opacity:'show',right:'0'}"
                palyout="{opacity:'hide',right:'0'}"
              >
                <p class="h1">7X12小时多渠道服务支持</p>
                <p>专业的团队为您提供 7*12*365 技术服务</p>
                <router-link to="/prolist">
                  <a href="" target="_blank" class="more">查看详情</a>
                </router-link>
              </div>
            </div>
          </li>
          <li class="list5">
            <div class="container">
              <h2 class="title">为什么选择企业服务平台</h2>
              <img
                src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/av-img5.png"
                alt=""
              />
              <div
                class="info"
                palyin="{opacity:'show',right:'0'}"
                palyout="{opacity:'hide',right:'0'}"
              >
                <p class="h1">VIP多对1服务</p>
                <p>
                  为了进一步提供更细致、更专业的服务，企业服务平台针对VIP客户，我司将通过建立微信群/QQ讨论组/电话等形式为您安排多人的技术团队为您提供售前售后的服务
                </p>
                <a href="home/index/prolist.html" target="_blank" class="more"
                  >查看详情</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.index-advantage .list5 {
  background: #fff;
}
</style>
