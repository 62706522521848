<!-- 企业安全 -->
<template>
  <div v-if="children.length">
    <div class="service-class" data-cnt="5">
      <div
        class="wit-banner wit-banner2"
        :style="{ background: 'no-repeat center url(' + img + ')' }"
      >
        <div>
          <h1>企业安全</h1>
          <p>企业安全，企业运营必备产品，欢迎采购！</p>
          <ul>
            <li class="text-over-flow"><span></span> 京东云星盾</li>
          </ul>
          <div class="fn-clear"></div>
          <router-link to="/prolist" class="btn-look border-r5">
            <span class="text-relblue">查看更多 &gt;</span>
          </router-link>
        </div>
      </div>
      <service />
    </div>
  </div>
</template>

<script>
import service from "./service-menu.vue";
export default {
  components: { service },
  data() {
    return {
      img: require("@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-sever-img5.png"),
    };
  },
};
</script>

<style scoped></style>
