<template>
  <div>
    <div v-if="homeStyle === 1" class="service-menu">
      <div style="width: 100%">
        <div
          class="service-menu-contact product"
          v-for="(item, index) in inside"
          :key="index"
        >
          <h4 class="text-over-flow">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>

          <div class="btn-key">
            <a
              v-show="item.merit"
              v-for="itemmerit in (item.merit || '').split(',')"
              :key="itemmerit"
              >{{ itemmerit }}</a
            >
          </div>
          <div class="yen">
            <a class="text-darkred" style="font-size: 30px">{{ item.price }}</a>
            <span>元/年</span>
          </div>
          <div class="btn-online">
            <!-- <router-link style="margin-right: 3%"  :to="{name:'buy',query:{id:item.prodId}}" class="pro-buy">在线咨询</router-link> -->
            <router-link
              style="float: right"
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="pro-buy"
              >在线购买</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="homeStyle === 3" class="service-menu">
      <div class="service-menu-tbbig">
        <div
          class="service-menu-tb product"
          v-for="(item, index) in inside"
          :key="index"
        >
          <h4 class="text-over-flow">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>
          <div class="ec-shop">
            <div style="float: left">
              <a class="text-darkred" style="font-size: 30px">78</a
              ><span>元/年</span>
            </div>
            <div style="float: right">
              <router-link
                :to="{ name: 'buy', query: { id: item.prodId } }"
                class="ec-btn pro-buy"
                >在线购买</router-link
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="homeStyle === 2" class="service-menu">
      <div class="service-menu-bankbig">
        <div
          class="service-menu-bank product"
          v-for="(item, index) in inside"
          :key="index"
        >
          <h4 class="text-over-flow" style="">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>
          <div style="float: left">
            <a class="text-darkred" style="font-size: 30px">{{ item.price }}</a>
            <span>元/年</span>
          </div>
          <div style="float: right">
            <router-link
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="bank-btn pro-buy"
              >在线购买</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["myStyle", "inside"],
  data() {
    return {
      homeStyle: this.myStyle,
      insideList: this.inside,
    };
  },
};
</script>

<style scoped>
.text-over-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(4) {
  margin-top: 20px;
  margin-right: 20px;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(5) {
  margin-top: 20px;
  margin-right: 20px;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(6) {
  margin-top: 20px;
  margin-right: 20px;
}
</style>
