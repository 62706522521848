<!-- 智慧营销 -->
<template>
  <div v-if="children.length">
    <div class="service-class" data-cnt="1">
      <div
        class="wit-banner wit-banner2"
        :style="{ background: 'no-repeat center url(' + img + ')' }"
      >
        <div>
          <h1>{{ parent.categoryName }}</h1>
          <p>{{ parent.description }}</p>
          <ul style="width: 240px">
            <li
              v-for="(item, index) in children"
              :key="index"
              class="text-over-flow"
            >
              <span></span>
              <router-link
                style="color: #fff"
                :to="{ name: 'buy', query: { id: item.prodId } }"
                >{{ item.prodName }}
              </router-link>
            </li>
          </ul>
          <div class="fn-clear"></div>
          <router-link
            :to="{ path: '/prolist', query: { categoryId: parent.categoryId } }"
            class="btn-look border-r5"
          >
            <span class="text-relblue">查看更多 &gt;</span>
          </router-link>
        </div>
      </div>
      <service :children="children" />
    </div>
  </div>
</template>

<script>
import service from "./service-menu.vue";
import { getParent, getShoplist } from "@/api/all.js";
export default {
  components: { service },
  data() {
    return {
      parent: {},
      children: [],
      img: require("@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-sever-img7.png"),
    };
  },
  created() {
    //获取父亲信息
    getParent().then((res) => {
      this.parent = res.data[2];
      this.categoryId = res.data[2].categoryId;
      //传递父亲id接收子类信息
      getShoplist({ categoryId: this.categoryId, showChild: true }).then(
        (res) => {
          this.children = res.data.rows.splice(0, 2);
        }
      );
    });
  },
};
</script>

<style scoped></style>

<!--
"categoryId": "85",
"parentId": "0",
"categoryName": "手机数码",
"homeStyle": null,
"description": "2342342434211111",
"pic": "https://scrm-1306343465.cos.ap-shanghai.myqcloud.com/2022/08/02/091f961c-2932-44f9-99ce-2bb4c1025652.jpg"
 -->
