<template>
  <div>
    <div class="index-entrance">
      <ul class="container">
        <li class="sort" @click="change(15)">
          <img
            src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-img1.png"
            class="img1"
          />
          <h3>智慧营销</h3>
          <p>网络推广、AI建站、在线客服，从建站到推广一站式服务</p>
        </li>
        <li class="sort" @click="change(18)">
          <img
            src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-img2.png"
            class="img1"
          />
          <h3>工商财税</h3>
          <p>公司注册、财务管理、资质代办，公司运营好帮手</p>
        </li>
        <li class="sort" @click="change(25)">
          <img
            src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-img3.png"
            class="img1"
          />
          <h3>知识产权</h3>
          <p>商标注册、专利申请、版权申请，保护您的网络资产</p>
        </li>
        <li class="sort" @click="change(34)">
          <img
            src="@/assets/static/template/Home/Zkeys/PC/Static/statics/images/index/index-img4.png"
            class="img1"
          />
          <h3>电商服务</h3>
          <p>电商入驻、电商代运营、拍摄服务，电子商务省心省力</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    change(categoryId) {
      this.$router.push({ name: "prolist", query: { categoryId } });
    },
  },
};
</script>

<style scoped></style>
