<!-- service-menure 热门服务三级 -->
<template>
  <div>
    <div class="service-menu">
      <div class="service-menu-bankbig">
        <div
          class="service-menu-bank product"
          v-for="(item, index) in children"
          :key="index"
        >
          <h4 class="text-over-flow" style="">{{ item.prodName }}</h4>
          <p>{{ item.brief }}</p>
          <div style="float: left">
            <a class="text-darkred" style="font-size: 30px">{{ item.price }}</a>
            <span>元/年</span>
          </div>
          <div style="float: right">
            <router-link
              :to="{ name: 'buy', query: { id: item.prodId } }"
              class="bank-btn pro-buy"
              >在线购买</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["children"],
};
</script>

<style scoped>
.text-over-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(4) {
  margin-top: 20px;
  margin-right: 20px;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(5) {
  margin-top: 20px;
  margin-right: 20px;
}
.index-service .service-class .service-menu .service-menu-bank:nth-child(6) {
  margin-top: 20px;
  margin-right: 20px;
}
</style>
