<!-- 新闻稿 -->
<template>
  <div>
    <dl class="press-release">
      <dt class="item2">
        <router-link :to="{ path: '/about/news/sciencenew' }">
          <a>科技新闻</a>
        </router-link>
      </dt>
      <dd>
        <p>
          <span>05-05</span>
          <a @click="toNewsPage('sciencenew', 'scienceDetail')"
            >百度布局移动互联网 推出更多无线产品</a
          >
        </p>
      </dd>
    </dl>

    <dl class="press-release">
      <dt class="item3">
        <router-link :to="{ path: '/about/news/companynew' }">
          <a>公司新闻</a>
        </router-link>
      </dt>
      <dd>
        <p>
          <span>05-05</span>
          <a @click="toNewsPage('companynew', 'companyDetail')">
            泛亚“红领巾在行动”团委活动
          </a>
        </p>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toNewsPage(path, type) {
      this.$router.push({ name: path, params: { type } });
    },
  },
};
</script>

<style scoped>
dd {
  cursor: pointer;
}
</style>
