<template>
  <div>
    <div class="service-class" data-cnt="1">
      <div
        class="wit-banner wit-banner2"
        :style="{
          background: `no-repeat center url(${imgList[item.homeStyle - 1]})`,
        }"
      >
        <div>
          <h1>{{ item.categoryName }}</h1>
          <p>{{ item.description }}</p>
          <ul style="width: 240px">
            <li
              v-for="(children, childrenindex) in childrenList"
              :key="childrenindex"
              class="text-over-flow"
            >
              <span></span>
              <router-link
                style="color: #fff"
                :to="{ name: 'buy', query: { id: children.prodId } }"
                >{{ children.prodName }}
              </router-link>
            </li>
          </ul>
          <div class="fn-clear"></div>
          <router-link
            :to="{ path: '/prolist', query: { categoryId: item.categoryId } }"
            class="btn-look border-r5"
          >
            <span class="text-relblue">查看更多 &gt;</span>
          </router-link>
        </div>
      </div>
      <inside :inside="insideList" :myStyle="homeStyle" />
    </div>
  </div>
</template>

<script>
import { getShoplist } from "@/api/all";
import inside from "../components/inside.vue";
export default {
  components: { inside },
  props: ["item"],
  data() {
    // 这里存放数据
    return {
      childrenList: [],
      categoryId: this.item.categoryId,
      homeStyle: this.item.homeStyle,
      insideList: [],
      imgList: [
        require("@/assets/img/img1.png"),
        require("@/assets/img/img2.png"),
        require("@/assets/img/img4.png"),
      ],
    };
  },
  methods: {
    getChildren() {
      getShoplist({ categoryId: this.categoryId, showChild: true, sortField: "sort", sortDirection: "desc"  }).then(
        (res) => {
          this.childrenList = res.data.rows.slice(0, 2);
          this.insideList = res.data.rows;

          if (this.homeStyle === 1) {
            this.insideList = this.insideList.slice(0, 2);
          } else if (this.homeStyle === 3) {
            this.insideList = this.insideList.slice(0, 3);
          } else if (this.homeStyle === 2) {
            this.insideList = this.insideList.slice(0, 6);
          }
        }
      );
    },
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.getChildren();
  },
};
</script>

<style scoped></style>
