<!-- 资质 -->
<template>
  <div class="index-honor">
    <div class="container">
      <div class="module-title">
        提供<span>安全合规</span>的企业服务
        <p>正规安全的企业服务，充分保障您的业务实践与业务安全</p>
      </div>
      <ul>
        <li>
          <a>
            <i class="img1"></i>
            <h5>IDC/云计算资质</h5>
          </a>
        </li>
        <li>
          <a>
            <i class="img2"></i>
            <h5>CNNIC</h5>
          </a>
        </li>
        <li>
          <a>
            <i class="img7"></i>
            <h5>中国移动</h5>
          </a>
        </li>
        <li>
          <a>
            <i class="img3"></i>
            <h5>中国电信</h5>
          </a>
        </li>
        <li>
          <a>
            <i class="img5"></i>
            <h5>中国联通</h5>
          </a>
        </li>
        <li>
          <a>
            <i class="img4"></i>
            <h5>软件著作权</h5>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
