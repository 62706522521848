<template>
  <el-carousel :interval="5000" arrow="never" :height="bannerHeight + 'px'">
    <el-carousel-item v-for="item in bannerList" :key="item">
      <router-link to="/prolist"><img class="banner-img" :src="item" alt="" /></router-link>
    </el-carousel-item>
  </el-carousel>
</template>

<script setup>
import { onMounted, ref } from "vue";
//轮播图
import bannerone from "@/assets/Uploads/2022-04-29/626b9dcb863bb.jpg";
import bannertwo from "@/assets/Uploads/2022-04-29/626b9df082eed.jpg";
const bannerList = ref([bannerone, bannertwo]);

//图片高度
const bannerHeight = ref(100);
const screenWidth = ref(0);
const setSize = () => {
  bannerHeight.value = (400 / 1920) * screenWidth.value * 1.6;
};

onMounted(() => {
  screenWidth.value = window.innerWidth;
  setSize();

  window.addEventListener("resize", () => {
    screenWidth.value = window.innerWidth;
    setSize();
  });
});
</script>

<style scoped>
.banner-img {
  background-size: 100%;
  width: 100%;
}
</style>
